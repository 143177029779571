.header {
	margin-bottom: 1rem;
	background-color: white;
	border-bottom: none;
	svg {
		width: 250px;
		height: 250px;
	}
}

.desc {
	height: 5rem;
}
