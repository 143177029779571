.container {
	display: inline-flex;
	position: relative;
	.overlay {
		background-color: black;
		opacity: 0.7;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		@supports (mix-blend-mode: color) {
			mix-blend-mode: color;
			opacity: unset;
		}
	}
}
