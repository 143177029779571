@use 'styles/dimen';
@use 'styles/layout';

@keyframes fadeIn {
	from {
		transform: translateY(20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes bounce {
	from {
		transform: translateY(0);
		opacity: 0;
	}
	to {
		transform: translateY(30px);
		opacity: 1;
	}
}

.header {
	color: white;
	@include layout.flexColumnMin100vh;
	background: url('https://res.cloudinary.com/vg9lab/image/upload/w_1750,h_1500,c_fill/v1643513748/samples/landscapes/stary-night_rkejnd.jpg')
		center center/cover;
	.headerTitle {
		margin-top: 1.5rem;
		max-width: dimen.$cardW * 1.1;
		text-align: center;
		line-height: 1.5rem;
		opacity: 0;
		animation: fadeIn 0.6s ease-in-out;
		animation-delay: 0.5s;
		animation-fill-mode: forwards;
		z-index: 2;
	}
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		min-height: 100vh;
		background-color: #00000075;
		z-index: 1;
	}
}

.card {
	position: relative;
	width: dimen.$cardW;
	height: dimen.$cardW;
	margin-top: 2 * dimen.$navH;
	z-index: 2;
	animation: fadeIn 0.6s ease-in-out;
	& > div {
		@include layout.absoluteFill;
		transform-style: preserve-3d;
		transition: transform 0.5s ease;
		& > div:first-of-type {
			@include layout.absoluteFill;
			backface-visibility: hidden;
			img {
				@include layout.absoluteFill;
				border-radius: 50%;
				padding: 1.5rem;
			}
		}
		& > div:nth-of-type(2) {
			@include layout.absoluteFill;
			@include layout.flexColumnCenter;
			transform: rotateY(180deg);
			backface-visibility: hidden;
			background-color: #00000075;
			border-radius: 10%;
			line-height: 2rem;
		}
	}
	&:hover > div {
		transform: rotateY(180deg);
	}
}

.expand {
	color: white;
	font-size: 2rem;
	z-index: 2;
	padding: 1rem;
	margin-top: 1rem;
	opacity: 0;
	animation: bounce 2s ease-out;
	animation-delay: 1.5s;
	animation-iteration-count: infinite;
}
