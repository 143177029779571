@use 'styles/dimen';

.root {
	padding-bottom: dimen.$navH;
	background-color: ghostwhite;
	max-width: 100vw;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	overflow-x: hidden;

	.header {
		text-align: center;
	}

	.title {
		font-weight: bold;
		margin-bottom: 1rem;
	}
	.desc {
		flex: 1;
	}
	svg {
		flex: 1;
	}
}
