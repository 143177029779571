.card {
	min-height: 150px;
	border-radius: 8px;
	background-color: #aaaaaa50;
	position: relative;
	overflow: hidden;
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	.label {
		background-color: #00000075;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 4px 8px;
		color: whitesmoke;
		h4 {
			font-weight: bold;
			margin: 0.6rem 0;
		}

		.icon {
			color: LightSkyBlue;
			font-size: 1.5rem;
		}
	}
}
