@use 'styles/color';

.nav {
	background-color: #00000050;
	padding-left: 0.5rem;
}

.brand {
	font-family: 'Audiowide';
	font-size: 1.1rem;
}

.logo {
	font-size: 2rem;
	color: white;
	margin-right: 0.5rem;
}
