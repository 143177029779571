@use 'styles/dimen';
@use 'styles/layout';
@use 'styles/color';

$illustrateW: 30vw;

.root {
	@include layout.flexColumnMin100vh;
	padding-top: dimen.$navH;
	padding-bottom: dimen.$navH;

	.illustrate {
		max-width: $illustrateW;
		max-height: $illustrateW;
		margin: 0.5rem auto;
	}

	.header {
		@include layout.flexCol;
		h2 {
			text-align: center;
		}
	}

	.section {
		width: min(100%, 960px);
		padding: 1rem;
		h3 {
			color: color.$black;
			font-size: 20px;
			font-weight: bold;
			font-style: italic;
			margin-top: 1rem;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid #aaaaaa50;
			text-align: end;
		}
	}
}
