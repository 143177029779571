@use 'styles/dimen';

.footer {
	height: dimen.$navH;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
	color: #080808;

	& > :first-child {
		display: flex;
		align-items: center;
	}

	.copyleft {
		font-size: 1.2rem;
		margin-bottom: 2px;
		margin-right: 2px;
	}

	.icon {
		font-size: 2rem;
		margin: auto 0.2rem;
	}
}
