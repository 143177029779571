@mixin absoluteFill {
	position: absolute;
	width: 100%;
	height: 100%;
}

@mixin flexColumnCenter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@mixin flexCol {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin flexColumnMin100vh {
	@include flexCol;
	min-height: 100vh;
}
